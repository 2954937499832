import ReactGA from "react-ga4";
import { createBrowserRouter } from "react-router-dom";

import Home from "../views/Home";
import LayoutDefault from "../layouts/LayoutDefault";
import GetCaseStudy from "../views/GetCaseStudy";
import Aboutus from "../views/Aboutus";
import LayoutSignin from "../layouts/LayoutSignin";
import Signup from "../views/Signup";
import CaseStudy from "../views/CaseStudy";
import Login from "../views/Login";
import Register from "../components/sections/Register";

ReactGA.initialize("G-1KCC5ZGDV2");
ReactGA.send("pageview");

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <LayoutDefault>
        <Home />
      </LayoutDefault>
    ),
  },
  {
    path: "/case-study",
    element: (
      <LayoutDefault>
        <CaseStudy />
      </LayoutDefault>
    ),
  },
  {
    path: "/case-study/download",
    element: <GetCaseStudy />,
  },
  {
    path: "/register",
    element: (
      <LayoutDefault>
        <Register />
      </LayoutDefault>
    ),
  },
  {
    path: "/aboutus",
    element: (
      <LayoutDefault>
        <Aboutus />
      </LayoutDefault>
    ),
  },
  {
    path: "/login",
    element: (
      <LayoutSignin>
        <Login />
      </LayoutSignin>
    ),
  },
  {
    path: "/signup",
    element: (
      <LayoutSignin>
        <Signup />
      </LayoutSignin>
    ),
  },
]);

export default router;
