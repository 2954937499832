import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "./partials/Logo";
import { AiOutlineMenu } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

const navConfig = [
  {
    href: { customRoute: false, link: "https://blog.uniblox.io/" },
    label: "Blog",
  },
  {
    href: {
      customRoute: false,
      link: "https://wellfound.com/company/uniblox/jobs",
    },
    label: "Jobs",
  },
  { href: { customRoute: true, link: "/case-study" }, label: "Case Study" },
  { href: { customRoute: true, link: "/aboutus" }, label: "About Us" },
];

const Header = () => {
  const location = useLocation();
  const [revealed, setRevealed] = useState(false);
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [toggleNavBar, setToggleNavBar] = useState(true);
  const specificElementRef = useRef(null);

  useEffect(() => {
    const delay = 400;
    const timer = setTimeout(() => {
      setRevealed(true);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleNavLinkClick = (path) => {
    setActiveLink(path);
    setToggleNavBar(true);
  };

  const handleHome = () => {
    setActiveLink(null);
  };

  return (
    <header className="bg-transparent sticky top-0 z-20 backdrop-blur-xl">
      <div
        className={`w-full transition-custom duration-custom ease-in-expo ${
          revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
        }`}
      >
        <nav className="px-8 sm:px-14 md:px-14 m-auto grid grid-cols-navBar-sm lg:grid-cols-navBar-lg items-center h-20">
          <div
            onClick={handleHome}
            className="w-full md:w-3/5 flex items-center justify-normal gap-3"
          >
            <Logo />
            <span className="font-600 text-white font-poppins text-2xl antialiased">
              <Link to="/">Uniblox</Link>
            </span>
          </div>
          <ul
            className="hidden text-base w-full lg:flex lg:justify-evenly lg:border lg:border-transparent-box-border-color items-center py-2 rounded-3xl"
            id="uniblox-navbar-lg"
          >
            {navConfig.map((navItem, idx) => {
              return (
                <li className="antialiased" key={idx}>
                  {navItem.href.customRoute ? (
                    <NavLink
                      to={navItem.href.link}
                      className={`${
                        activeLink === navItem.href.link
                          ? "text-transparent bg-clip-text bg-gradient-to-r from-custom-glow-blue to-custom-glow-violet"
                          : "text-white"
                      } hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-custom-glow-blue hover:to-custom-glow-violet`}
                      onClick={() => handleNavLinkClick(navItem.href.link)}
                      key={idx}
                    >
                      {navItem.label}
                    </NavLink>
                  ) : (
                    <a
                      href={navItem.href.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-custom-glow-blue hover:to-custom-glow-violet"
                    >
                      {navItem.label}
                    </a>
                  )}
                </li>
              );
            })}
          </ul>
          <div
            id="uniblox-navbar-sm"
            ref={specificElementRef}
            className={`w-4/5 text-custom-white fixed bg-black  grid grid-rows-[auto_1fr] text-center top-0 min-h-svh opacity-95 ${
              toggleNavBar ? "-left-[200%]" : "right-0"
            } bg-gradient-to-b from-[rgba(22,24,33,0.7)] to-[rgba(22,24,33,0.39)] backdrop-blur-md`}
          >
            <RxCross2
              style={{ justifySelf: "end", margin: "1.5rem" }}
              size={24}
              onClick={() => setToggleNavBar(true)}
            />
            <ul className="flex flex-col justify-center">
              {navConfig.map((navItem, idx) => {
                return (
                  <li className="p-5" key={idx}>
                    {navItem.href.customRoute ? (
                      <NavLink
                        to={navItem.href.link}
                        className={`${
                          activeLink === navItem.href.link
                            ? "text-transparent bg-clip-text bg-gradient-to-r from-[#482BD9] to-[#41D6CD]"
                            : "text-white"
                        } hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#482BD9] hover:to-[#41D6CD]`}
                        onClick={() => handleNavLinkClick(navItem.href.link)}
                        key={idx}
                      >
                        {navItem.label}
                      </NavLink>
                    ) : (
                      <a
                        href={navItem.href.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#482BD9] hover:to-[#41D6CD]"
                      >
                        {navItem.label}
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex gap-5 lg:gap-0 justify-self-end">
            <button className="bg-neutral-100/10 sm:flex text-white border border-transparent-box-border-color border-b-custom-blue font-poppins font-500 h-9 text-sm items-center justify-self-center sm:justify-self-end rounded-lg hover:bg-gradient-to-r hover:from-custom-glow-blue hover:to-custom-glow-violet">
              <a
                href="https://app.uniblox.io/"
                target="_blank"
                rel="noopener noreferrer"
                className="px-6 py-2 opacity-100 font-600"
              >
                LOGIN
              </a>
            </button>
            <button>
              <AiOutlineMenu
                onClick={() => setToggleNavBar(!toggleNavBar)}
                className="text-white text-2xl lg:hidden"
              />
            </button>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
