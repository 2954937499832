import React from "react";

import Logo from "./partials/Logo";
import FooterSocial from "./partials/FooterSocial";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="mb-28">
      <Separator />
      <div className="bg-custom-dark">
        <div className="px-24 pt-9">
          <div>
            <div className="text-center md:text-left flex flex-col md:flex-row md:gap-20 md:justify-between">
              <div>
                <div className="flex gap-6 items-center justify-center md:justify-normal">
                  <Logo height={65} width={55} />
                  <h3 className="text-white text-4xl font-600">Uniblox</h3>
                </div>
                <p className="text-[#757575] py-7 font-poppins font-400 scale-x-100 tracking-wide antialiased text-xs md:text-base md:max-w-2xl">
                  Revolutionize your insurance business with our cutting-edge
                  e-commerce platform. Say goodbye to legacy systems, manual
                  workflows, and paper hassles. Propel your business into the
                  future, streamlining processes for efficiency and growth.
                </p>
                <div className="pt-3 pb-12 text-custom-blue font-poppins">
                  <div className="text-sm">
                    &copy; {currentYear} Uniblox, all rights reserved
                  </div>
                </div>
              </div>
              <FooterSocial />
            </div>
            {/* //~! Not needed for now - but can be used in future */}
            {/* <div className="flex gap-16 justify-around text-white">
              <ul>
                <li className="pb-12 font-600 text-2xl">Products</li>
                <li className="text-[#8A8A8A] text-sm md:text-xl pb-6">
                  AI Assistant
                </li>
                <li className="text-[#8A8A8A] text-sm md:text-xl pb-6">CRM</li>
                <li className="text-[#8A8A8A] text-sm md:text-xl pb-6">
                  Invoicing
                </li>
                <li className="text-[#8A8A8A] text-sm md:text-xl pb-6">
                  Pricing
                </li>
                <li className="text-[#8A8A8A] text-sm md:text-xl pb-6">
                  Website builder
                </li>
              </ul>
              <ul>
                <li className="pb-12 font-600 text-2xl">Company</li>
                <li className="text-[#8A8A8A] text-sm md:text-xl pb-6">
                  About
                </li>
                <li className="text-[#8A8A8A] text-sm md:text-xl pb-6">
                  Newsletter
                </li>
                <li className="text-[#8A8A8A] text-sm md:text-xl pb-6">
                  Privacy policy
                </li>
                <li className="text-[#8A8A8A] text-sm md:text-xl pb-6">
                  Support
                </li>
                <li className="text-[#8A8A8A] text-sm md:text-xl pb-6">
                  Terms of Service
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export const Separator = () => {
  return <div className="h-1 colored-separator mx-10 md:mx-24"></div>;
};

export default Footer;
