import React, { useEffect, useRef, useState } from "react";
import { useRevealOnScrollWhenInView } from "../../hooks/useRevealOnScroll";

const DURATION = 1000;

const Statistics = () => {
  const { ref, revealed } = useRevealOnScrollWhenInView({ time: 800 });
  const [stats, setStats] = useState(Statistics.info);
  const countsRef = useRef(stats);
  useEffect(() => {
    countsRef.current = stats;
  }, [stats]);
  const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3);

  useEffect(() => {
    let animationFrameId;
    const incrementCount = (index, end) => {
      const startTime = performance.now();
      const startValue = countsRef.current[index].initialNumber;
      const updateCount = () => {
        const currentTime = performance.now();
        const elapsed = currentTime - startTime;
        const progress = Math.min(1, elapsed / DURATION);
        const easedProgress = easeOutCubic(progress);
        const newValue = startValue + (end - startValue) * easedProgress;
        setStats((prevCounts) => {
          const _prevCounts = [...prevCounts];
          _prevCounts[index].initialNumber = Math.ceil(newValue);
          return _prevCounts;
        });
        if (progress < 1) {
          animationFrameId = requestAnimationFrame(updateCount);
        }
      };
      updateCount();
    };
    if (revealed) {
      stats.forEach((count, index) => {
        incrementCount(index, count.maxNumber);
      });
    }
    return () => cancelAnimationFrame(animationFrameId);
  }, [revealed]);

  return (
    <div
      className={`bg-transparent-box-color py-7 my-10 ${
        revealed ? "opacity-100 translate-y-0" : "opacity-0 translate-y-2"
      }`}
      ref={ref}
    >
      <div className="max-w-[80%] m-auto flex flex-wrap justify-center items-center">
        {revealed &&
          stats.map((count, index) => (
            <div
              key={index}
              className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4 text-center"
            >
              <p className="text-4xl font-bold text-white mb-3">
                {count.initialNumber}
                {count.suffix ?? ""}
              </p>
              <p className="text-center text-sm text-custom-glow-blue font-bold mt-4">
                {count.title}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

Statistics.info = [
  { title: "APPLICATIONS", initialNumber: 0, maxNumber: 16000, suffix: "+" },
  { title: "GROUPS", initialNumber: 0, maxNumber: 750, suffix: "+" },
  {
    title: "EMAIL DELIVERY RATE",
    initialNumber: 0,
    maxNumber: 100,
    suffix: "%",
  },
  { title: "PRODUCTS", initialNumber: 0, maxNumber: 8 },
];

export default Statistics;
