import axiosInstance from "./axiosInstance";

export const fetchCaseStudy = ({ params }) => {
  return axiosInstance.get(`/main/case-study`, {
    params,
  });
};

export const subcribeToMailingList = ({
  email,
  mailingListType,
  mailingListIdentifier,
}) => {
  return axiosInstance.post(`/main/mailing-list`, {
    email,
    mailingListType,
    mailingListIdentifier,
  });
};

export const subcribeToJan2024MailingList = ({ email }) => {
  return subcribeToMailingList({
    email,
    mailingListType: "PROMOTION",
    mailingListIdentifier: "JAN2024",
  });
};
